// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '~@angular/material'as mat;
$theme-colors: ("primary": #fdc500,
  "red": #e30613,
  "deep": #1d1d1b,
  "dull": #e5e5e5,
  "green": #00c63c,
);
$font-family-sans-serif: "Roboto",
sans-serif;
// font-family: 'Roboto', sans-serif;
// font-family: 'Secular One', sans-serif;

// @use '~@angular/material' as mat;
@import "~bootstrap/scss/bootstrap";
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// @include mat.core();

@include mat.core();
@import "./color-palette";

@import "~ngx-owl-carousel-o/lib/styles/scss/owl.carousel";
@import "~ngx-owl-carousel-o/lib/styles/scss/owl.theme.default";
// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$default-primary: mat.define-palette($pallet);
$default-accent: mat.define-palette($pallet-accent, A200, A100, A400);
// The warn palette is optional (defaults to red).
$default-warn: mat.define-palette(mat.$red-palette);
// Create the theme object (a Sass map containing all of the palettes).
$default-theme: mat.define-light-theme($default-primary, $default-accent, $default-warn);
// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($default-theme);

/* You can add global styles to this file, and also import other style files */
* {
  box-sizing: border-box;
  outline: none !important;
}

body {
  line-height: normal;
}

a {
  transition: ease 0.3s;
  cursor: pointer;
}

.btn-c {
  position: relative;
  box-shadow: 0px 12px 18px rgba(0, 0, 0, 0.15);
  border-radius: 0;

  &::after {
    content: "";
    border: 1px solid #ebebeb;
    pointer-events: none;
    position: absolute;
    left: 3px;
    right: 3px;
    top: 3px;
    bottom: 3px;
  }
}

.btn-dark-c {
  position: relative;
  box-shadow: 0px 12px 18px rgba(0, 0, 0, 0.15);
  border-radius: 0;

  &::after {
    content: "";
    border: 1px solid #4d4d4d;
    pointer-events: none;
    position: absolute;
    left: 3px;
    right: 3px;
    top: 3px;
    bottom: 3px;
  }
}

button {
  transition: ease 0.3s;
  cursor: pointer;
}

.cursor-pointer,
.cp {
  cursor: pointer;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Secular One", sans-serif;
}

@for $fw from 0 to 10 {
  .fw-#{$fw} {
    font-weight: $fw * 100;
  }
}

// SWEET ALERT
// @import '~@sweetalert2/theme-dark/dark.scss';

app-loader {
  display: block;
}

.mat-icon {
  vertical-align: middle;
}

// DYNAMIC WIDTH
@mixin DYNAMIC_WIDTH_PERCENTAGE {
  @for $i from 1 through 100 {
    .width-#{$i} {
      width: #{$i + "%"};
    }
  }
}

@include DYNAMIC_WIDTH_PERCENTAGE;

@mixin DYNAMIC_WIDTH_PEXELS {
  @for $i from 1 through 2000 {
    .pxwidth-#{$i} {
      width: #{$i + "px"};
    }
  }
}

@include DYNAMIC_WIDTH_PEXELS;

@media (max-width: 1600px) {
  :root {
    font-size: 14px;
  }
}

input,
textarea,
.select,
.mat-select-trigger {
  width: 100%;
  height: auto;
  padding: 15px;
  background-color: transparent;
  border: 1px solid #d6dbdf;
  border-radius: 4px;
  outline: none;
  font-size: 15px;
  font-family: "Roboto";
  color: #000;
  margin-bottom: 5px;

  &::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #000;
    opacity: 1;
  }

  &::-moz-placeholder {
    /* Firefox 19+ */
    color: #000;
    opacity: 1;
  }

  &:-ms-input-placeholder {
    /* IE 10+ */
    color: #000;
    opacity: 1;
  }

  &:-moz-placeholder {
    /* Firefox 18- */
    color: #000;
    opacity: 1;
  }

  &::placeholder {
    color: #c2c0c0;
  }
}

input,
.select,
.mat-select-trigger {
  height: 46px;
}

.page-no-select {
  .mat-select-trigger {
    padding: 10px 7px;
    height: auto;
  }
}

body.swal2-toast-shown .swal2-container {
  z-index: 999999999;
}

.iti {
  width: 100% !important;
}

@media (min-width: 1200px) {

  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1260px !important;
  }
}

.ngx-pagination .current {
  background-color: var(--primary) !important;
  border-radius: 52%;
}

.ngx-pagination {
  margin: 20px auto;
  display: flex;
  justify-content: center;
}

.ngx-pagination a,
.ngx-pagination button {
  color: var(--red) !important;
}

.logoslide {
  .owl-carousel .owl-item {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 145px;
  }

  .owl-carousel .owl-item img {
    width: auto;
  }

  .owl-prev {
    width: 30px;
    height: 30px;
    background: #fff url(./assets/images/left.png) no-repeat center center !important;
    background-size: 50% !important;
    border: 1px solid #7a7a7a;
    border-radius: 50% !important;
    margin-right: 70px !important;

    &:hover {
      border-color: #f7941e;
      background: #fff url(./assets/images/leftorg.png) no-repeat center center !important;
    }
  }

  .owl-next {
    width: 30px;
    height: 30px;
    background: #fff url(./assets/images/right.png) no-repeat center center !important;
    background-size: 50% !important;
    border: 1px solid #7a7a7a;
    border-radius: 50% !important;
    margin-left: 70px !important;

    &:hover {
      border-color: #f7941e;
      background: #fff url(./assets/images/rightorg.png) no-repeat center center !important;
    }
  }

  .owl-nav {
    background: url(./assets/images/line.png) no-repeat center center;
  }
}

.mat-error {
  color: #f44336;
  display: block;
  width: 100%;
}

.inputArea {
  margin: 0 -4px;

  .main-container {
    padding: 1rem !important;
    height: 50vh;
    overflow: auto;
    margin: 0 -1rem;

    // &::-webkit-scrollbar {
    //   width: 4px;
    // }
    .container {
      padding: 0;

      .row {
        margin: 0;
      }

      input,
      .select {
        height: 46px;
      }

      .col-lg-6 {
        margin: auto;
      }
    }
  }
}

.packageTag {
  background-color: #c72311;
}

.packageRow {
  height: 40px;
}

.packageRow1 {
  height: 40px;
  width: 40px;
}

// .view-modal {
//   background: #1a1f26;

//   .mat-dialog-container {
//     background: #1a1f26;
//   }

//   .mat-dialog-content {
//     max-height: 90vh !important;
//   }
// }

.main-container {
  .col-lg-12 {
    .fs-48 {
      margin-left: 12%;

      @media(max-width: 1199px) {
        margin-left: 0 !important;
        font-size: 26px;
      }
    }
  }
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #e30613;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #e30613;
}
.accProDetailForm{
  .main-container{
    height: auto;
    margin: 0;
    .container{
      max-width: 100%;
    }
  }
}

.image-b-space{ min-height: 48PX !important;}
::ng-deep {
  tr.mat-row, tr.mat-footer-row{ height:auto !important; }

}

.page-inner-heading {
  @media (max-width: 767px) {
    margin-bottom: 15px !important;
  }
}

.main-container {
  @media (max-width: 767px) {
    padding-bottom: 20px !important;
  }
}
